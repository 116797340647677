import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const MasterPODetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [masterPO, setMasterPO] = useState(null);
  const [childPODetails, setChildPODetails] = useState([]);
  const [aggregatedProducts, setAggregatedProducts] = useState([]);
  const [availablePos, setAvailablePos] = useState([]); // State for available POs
  const [selectedPos, setSelectedPos] = useState([]); // State for selected available POs
  const [error, setError] = useState(null);
  const [poName, setPOName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [priceWarnings, setPriceWarnings] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false); // State to manage Add PO modal
  const [selectedChildPO, setSelectedChildPO] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMasterPO = async () => {
      try {
        const masterPOResponse = await axios.get(`https://shopifybackend.mustafaemresahin.com/api/master-pos/${id}`);
        setMasterPO(masterPOResponse.data);
        setPOName(masterPOResponse.data.poName || '');

        const productsResponse = await axios.get(`https://shopifybackend.mustafaemresahin.com/api/master-pos/${id}/products`);
        setAggregatedProducts(productsResponse.data);

        const childPOIds = masterPOResponse.data.childPos || [];
        const childDetailsPromises = childPOIds.map((childId) => axios.get(`https://shopifybackend.mustafaemresahin.com/api/pos/${childId}`));
        const childDetailsResponses = await Promise.all(childDetailsPromises);
        setChildPODetails(childDetailsResponses.map((res) => res.data));

        const productPrices = {};
        const warnings = {};
        childDetailsResponses.forEach((response) => {
          const products = response.data.products || [];
          products.forEach((product) => {
            const { productId, productTitle, unitPrice } = product;
            if (!productPrices[productId]) {
              productPrices[productId] = { productTitle, prices: [unitPrice] };
            } else if (!productPrices[productId].prices.includes(unitPrice)) {
              productPrices[productId].prices.push(unitPrice);
            }
          });
        });

        Object.keys(productPrices).forEach((productId) => {
          if (productPrices[productId].prices.length > 1) {
            warnings[productId] = productPrices[productId].prices;
          }
        });
        setPriceWarnings(warnings);
        setLoading(false);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchMasterPO();
  }, [id]);

  const fetchAvailablePos = async () => {
    try {
      const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/available-pos'); // Fetch available child POs
      setAvailablePos(response.data);
    } catch (error) {
      console.error('Error fetching available POs:', error);
    }
  };

  const handleSaveName = async () => {
    try {
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/master-pos/${id}`, { poName });
      setSnackbarMessage('PO name updated successfully');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating PO name:', error);
      setError('Failed to update PO name');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(aggregatedProducts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
    XLSX.writeFile(workbook, `MasterPO_${new Date().toISOString()}.xlsx`);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Purchase Order', 20, 10);
    doc.autoTable({
      head: [['Product Name', 'Quantity Ordered', 'Unit Price', 'Total Price']],
      body: aggregatedProducts.map((product) => [
        product.productTitle,
        product.quantityOrdered,
        product.unitPrice.toFixed(2),
        product.totalPrice.toFixed(2),
      ]),
    });
    doc.save(`MasterPO_${new Date().toISOString()}.pdf`);
  };

  const handleEditPO = (poId) => {
    navigate(`/po-edit/${poId}`);
  };

  const handleDeletePO = async () => {
    if (selectedChildPO) {
      try {
        // Call the API to remove child PO from the Master PO
        await axios.put(`https://shopifybackend.mustafaemresahin.com/api/master-pos/${id}/remove-child/${selectedChildPO}`);
  
        setSnackbarMessage('PO removed from Master PO successfully');
        setOpenSnackbar(true);
  
        // Reload the page to reflect changes
        window.location.reload(); // This will reload the entire page
  
      } catch (error) {
        console.error('Error removing PO:', error);
        setError('Failed to remove PO');
      }
    }
    setOpenDeleteDialog(false);
  };

  const confirmDeletePO = (poId) => {
    setSelectedChildPO(poId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  // Open the Add PO Modal
  const handleOpenAddModal = () => {
    fetchAvailablePos(); // Fetch available POs when opening the modal
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setSelectedPos([]); // Clear selected POs when closing the modal
  };

  // Handle selection of available POs
  const handleToggleChildPO = (poId) => {
    if (selectedPos.includes(poId)) {
      setSelectedPos(selectedPos.filter((id) => id !== poId));  // Remove from selection
    } else {
      setSelectedPos([...selectedPos, poId]);  // Add to selection
    }
  };

  // Handle adding selected available POs to Master PO
  const handleAddChildPOs = async () => {
    if (selectedPos.length === 0) {
      setSnackbarMessage('Please select at least one child PO.');
      setOpenSnackbar(true);
      return;
    }

    try {
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/master-pos/${id}/add-children`, {
        childPoIds: selectedPos,  // Send selected POs to backend
      });

      setSnackbarMessage('Child POs added successfully');
      setOpenSnackbar(true);
      setSelectedPos([]);  // Clear selection after adding
      handleCloseAddModal(); // Close the modal
      window.location.reload();  // Reload to reflect the updated list

    } catch (error) {
      console.error('Error adding child POs:', error);
      setSnackbarMessage('Failed to add child POs');
      setOpenSnackbar(true);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error) return <Typography color="error">{error}</Typography>;
  if (!masterPO) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Purchase Order Details
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>Created At:</strong> {masterPO.combinedOrderDate ? new Date(masterPO.combinedOrderDate).toLocaleString() : 'N/A'}
      </Typography>

      <TextField
        label="Purchase Order Name"
        value={poName}
        onChange={(e) => setPOName(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleSaveName} sx={{ mb: 3 }}>
        Save Name
      </Button>

      <Divider sx={{ mb: 3 }} />

      <Typography variant="h5" gutterBottom>
        Child Purchase Lists
      </Typography>
      {childPODetails.length === 0 ? (
        <Typography>No child Purchase Lists found for this Purchase Order.</Typography> // Show this message if no child POs
      ) : (
        <Grid container spacing={2}>
          {childPODetails.map((po, index) => (
            <Grid item xs={12} md={6} lg={4} key={po._id || index}>
              <Card sx={{ minHeight: 180 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {po.poName || 'Unnamed Purchase Order'}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Platform: {po.platform || 'Unknown platform'}
                  </Typography>
                  <Typography variant="body2">
                    Created At: {po.createdAt ? new Date(po.createdAt).toLocaleString() : 'Unknown date'}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton aria-label="edit" color="primary" onClick={() => handleEditPO(po._id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete" color="secondary" onClick={() => confirmDeletePO(po._id)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Divider sx={{ mt: 3, mb: 3 }} />

      {/* Button to open Add PO Modal */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleOpenAddModal}
      >
        Add Child Purchase Lists
      </Button>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h5" gutterBottom>
        Aggregated Products
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">Quantity Ordered</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedProducts.map((product) => (
              <TableRow key={product.productId}>
                <TableCell>
                  {product.productTitle}
                  {priceWarnings[product.productId] && (
                    <Tooltip title={`Unit price discrepancy: ${priceWarnings[product.productId].join(', ')}`}>
                      <Typography variant="body2" color="error" sx={{ display: 'inline', ml: 1 }}>
                        (Price discrepancy)
                      </Typography>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="right">{product.quantityOrdered}</TableCell>
                <TableCell align="right">${product.unitPrice.toFixed(2)}</TableCell>
                <TableCell align="right">${product.totalPrice.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" color="primary" onClick={exportToExcel} sx={{ mt: 3 }}>
        Export to Excel
      </Button>
      <Button variant="contained" color="secondary" onClick={exportToPDF} sx={{ mt: 3, ml: 2 }}>
        Export to PDF
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Add Child PO Modal */}
      <Dialog open={openAddModal} onClose={handleCloseAddModal}>
        <DialogTitle>Select Child Purchase Lists to Add</DialogTitle>
        <DialogContent>
          <List>
            {availablePos.length > 0 ? (
              availablePos.map((po) => (
                <ListItem key={po._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPos.includes(po._id)}
                        onChange={() => handleToggleChildPO(po._id)}
                      />
                    }
                    label={`${po.poName} (${po.platform})`}
                  />
                </ListItem>
              ))
            ) : (
              <Typography>No available child Purchase Lists found</Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddChildPOs} color="primary">
            Add Purchase Lists
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this Purchase Order from the Master PO? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePO} color="error" autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MasterPODetails;
