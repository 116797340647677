import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Paper, Divider, CircularProgress } from '@mui/material';
import axios from 'axios';
import InventoryGraph from '../graphs/InventoryGraph';
import SalesGraph from '../graphs/SalesGraph';
import OrdersGraph from '../graphs/OrdersGraph';

function TodaysSalesProductDetailPage() {
  const { id } = useParams();
  const [productData, setProductData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://shopifybackend.mustafaemresahin.com/api/product/${id}`)
      .then(response => setProductData(response.data))
      .catch(error => console.error('Error fetching product data:', error));
  }, [id]);

  if (!productData) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );

  return (
    <Box p={3} maxWidth="lg" mx="auto">
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Button onClick={() => navigate('/todays-graphs')} variant="contained" color="primary" sx={{ mb: 2 }}>
          Back to Today's Graphs
        </Button>
        <Typography variant="h4" gutterBottom>
          {productData.title}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Product ID: {id}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box mb={4} sx={{zoom: 1.25}}>
          <InventoryGraph productId={id} />
        </Box>
        <Divider sx={{ my: 4 }} />
        <Box mb={4} sx={{zoom: 1.25}}>
          <SalesGraph inventoryLevels={productData.inventoryLevels} productId={id} />
        </Box>
        <Divider sx={{ my: 10 }} />
        <Box mb={4} sx={{zoom: 1.25}}>
          <OrdersGraph inventoryLevels={productData.inventoryLevels} />
        </Box>
      </Paper>
    </Box>
  );
}

export default TodaysSalesProductDetailPage;
