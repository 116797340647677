import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  CircularProgress,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';

const MasterPOListPage = () => {
  const [masterPOs, setMasterPOs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const fetchMasterPOs = async () => {
    try {
      const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/master-pos');
      setMasterPOs(response.data);
    } catch (error) {
      console.error('Error fetching Master POs:', error);
      setError('Failed to fetch Master POs');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://shopifybackend.mustafaemresahin.com/api/master-pos/${id}`);
      setSnackbarMessage('PO deleted successfully');
      setOpenSnackbar(true);
      setMasterPOs((prevPOs) => prevPOs.filter((po) => po._id !== id)); // Remove deleted PO from state
    } catch (error) {
      console.error('Error deleting PO:', error);
      setSnackbarMessage('Failed to delete PO');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchMasterPOs();
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Purchase Orders
      </Typography>

      {/* Enhanced Create Master PO Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/create-master-po')}
          sx={{ textTransform: 'none', fontWeight: 'bold', borderRadius: 2 }}
        >
          Create Purchase Order
        </Button>
      </Box>

      {/* Display error message */}
      {error && <Typography color="error">{error}</Typography>}

      {/* Loading indicator */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : masterPOs.length === 0 ? (
        <Typography variant="body1" align="center" sx={{ mt: 4 }}>
          No Purchase Orders available.
        </Typography>
      ) : (
        <Paper sx={{ p: 2, borderRadius: 2, boxShadow: 1, border: '1px solid #ddd' }}>
          <List>
            {masterPOs.map((po) => (
              <ListItem
                key={po._id}
                button
                onClick={() => navigate(`/master-po/${po._id}`)}
                sx={{
                  '&:hover': { backgroundColor: '#f5f5f5', cursor: 'pointer' },
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1, // Add spacing between items
                  borderRadius: 1,
                  padding: '12px 16px',
                  border: '1px solid #e0e0e0', // Add slight borders around each list item
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                      {po.poName || 'Unnamed PO'}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" component="div">
                      <Box display="flex" alignItems="center">
                        <CalendarTodayIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />
                        <Typography variant="body2" component="span" color="textSecondary">
                          Created At: {po.createdAt ? new Date(po.createdAt).toLocaleString() : 'Unknown date'}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <ListAltIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />
                        <Typography variant="body2" component="span" color="textSecondary">
                          Number of Child Purchase Lists: {po.childPos ? po.childPos.length : 0}
                        </Typography>
                      </Box>
                    </Typography>
                  }
                />

                {/* Delete button */}
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from triggering the navigate function
                    handleDelete(po._id);
                  }}
                  sx={{
                    backgroundColor: '#ffebee',
                    '&:hover': {
                      backgroundColor: '#f44336',
                      color: 'white',
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MasterPOListPage;
