import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Button,
  Grid,
} from '@mui/material';
import axios from 'axios';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [nonZeroProducts, setNonZeroProducts] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const fetchData = () => {
    setLoading(true);
    axios
      .get('https://shopifybackend.mustafaemresahin.com/api/products-with-data')
      .then((response) => {
        const products = response.data || [];
        const nonZero = products.filter(
          (product) =>
            product.inventoryLevels &&
            product.inventoryLevels.length > 0 &&
            product.inventoryLevels[product.inventoryLevels.length - 1].quantity > 0
        );
        console.log(products);
        setNonZeroProducts(nonZero);
        setProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setLoading(false);
      });
  };

  const fetchTopSellingProducts = () => {
    axios
      .get('https://shopifybackend.mustafaemresahin.com/api/top-selling-products')
      .then((response) => {
        const products = response.data || [];
        setTopSellingProducts(products);
        if (products.length > 0 && products[0].lastUpdated) {
          setLastUpdated(products[0].lastUpdated);
        }
      })
      .catch((error) => {
        console.error('Error fetching top selling products:', error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchTopSellingProducts();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUpdateAllClick = () => {
    setUpdating(true);
    axios
      .get('https://shopifybackend.mustafaemresahin.com/api/recalculate-total-orders')
      .then((response) => {
        console.log('Total orders updated successfully for all products:', response.data);
        fetchTopSellingProducts();
        setUpdating(false);
      })
      .catch((error) => {
        console.error('Error updating total orders for all products:', error);
        setUpdating(false);
      });
  };

  const getDisplayedProducts = () => {
    if (selectedTab === 0) return nonZeroProducts;
    if (selectedTab === 2) return topSellingProducts;
    return products;
  };

  // Convert lastUpdated to Central US Time
  const formatLastUpdated = (dateString) => {
    const options = {
      timeZone: 'America/Chicago', // Central Time (US & Canada)
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }} sx={{ zoom: 1.25 }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" gutterBottom sx={{minWidth: '300px'}}>
            Product Data Overview
          </Typography>
          {selectedTab === 2 && (
            <Grid container direction="column" alignItems="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpdateAllClick}
                disabled={updating}
              >
                {updating ? 'Updating...' : 'Update All Total Orders'}
              </Button>
              {lastUpdated && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                  Last updated: {formatLastUpdated(lastUpdated)}
                </Typography>
              )}
            </Grid>
          )}
        </Box>
        <br />
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="Non-Zero Inventory" />
          <Tab label="All Products" />
          <Tab label="Top Selling" />
        </Tabs>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Title</TableCell>
                  {selectedTab !== 2 && <TableCell align="right">Latest Inventory Date</TableCell>}
                  {selectedTab !== 2 && <TableCell align="right">Inventory Level</TableCell>}
                  {selectedTab === 2 && <TableCell align="right">Total Orders</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {getDisplayedProducts().map((product) => (
                  <TableRow key={product.shopifyId || product.productId}>
                    <TableCell>{product.title}</TableCell>
                    {selectedTab !== 2 && (
                      <TableCell align="right">
                        {product.inventoryLevels && product.inventoryLevels.length > 0
                          ? product.inventoryLevels[product.inventoryLevels.length - 1].date.split('T')[0]
                          : 'No Data'}
                      </TableCell>
                    )}
                    {selectedTab !== 2 && (
                      <TableCell align="right">
                        {product.inventoryLevels && product.inventoryLevels.length > 0
                          ? product.inventoryLevels[product.inventoryLevels.length - 1].quantity
                          : 'No Data'}
                      </TableCell>
                    )}
                    {selectedTab === 2 && (
                      <TableCell align="right">
                        {product.totalOrders !== undefined ? product.totalOrders : 'No Data'}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {selectedTab === 0 && nonZeroProducts.length === 0 && (
              <Typography variant="body1" color="textSecondary" style={{ marginTop: '20px' }}>
                No products have non-zero inventory levels.
              </Typography>
            )}
            {selectedTab === 2 && topSellingProducts.length === 0 && (
              <Typography variant="body1" color="textSecondary" style={{ marginTop: '20px' }}>
                No top selling products data available.
              </Typography>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
}

export default ProductList;
