import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Box,
  Slide,
  IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';

// Sleek, minimalist header design
const SleekHeader = styled('div')(({ theme }) => ({
  backgroundColor: '#f5f5f5', // Soft neutral background color
  padding: theme.spacing(3),
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)', // Light shadow for depth
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

// Container for header content alignment
const HeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

// Simplified and elegant title
const HeaderTitle = styled(Typography)({
  fontSize: '1.8rem',
  fontWeight: 600,
  color: '#333', // Darker, neutral font color for clarity
});

// Subtle button style for back icon
const BackButton = styled(IconButton)({
  color: '#0072ff', // Slightly more colorful for contrast
  marginRight: '16px',
});

// Fancy table and button styles from previous iteration
const FancyTable = styled(TableContainer)(({ theme }) => ({
  borderRadius: '8px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  '& .MuiTableCell-root': {
    padding: theme.spacing(2),
    fontSize: '16px',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: '#f4f8fb',
  },
}));

const FancyButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#64b5f6',
  },
}));

function ProductsPage() {
  const [value, setValue] = useState(0);
  const [productsWithBarcode, setProductsWithBarcode] = useState([]);
  const [productsWithoutBarcode, setProductsWithoutBarcode] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newBarcode, setNewBarcode] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          'https://shopifybackend.mustafaemresahin.com/api/inventory-counts/products-with-and-without-barcodes'
        );
        setProductsWithBarcode(response.data.productsWithBarcode);
        setProductsWithoutBarcode(response.data.productsWithoutBarcode);
      } catch (error) {
        setError('Failed to load products.');
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setNewBarcode('');
    setOpenModal(false);
  };

  const handleSaveBarcode = async () => {
    setSuccess(true);
    setOpenSnackbar(true);
    console.log(success);

    if (!newBarcode) {
      setError('Please enter a barcode.');
      return;
    }

    try {
      const response = await axios.put(
        `https://shopifybackend.mustafaemresahin.com/api/inventory-counts/products/${selectedProduct.id}/update-barcode`,
        { barcode: newBarcode }
      );

      console.log(response);
      console.log(selectedProduct);
      console.log(selectedProduct.id, newBarcode, typeof selectedProduct.id, typeof newBarcode);

      setSnackbarMessage(`Barcode updated successfully for ${selectedProduct.title}`);
      setOpenSnackbar(true);

      if (!selectedProduct.barcode) {
        setProductsWithoutBarcode((prev) => prev.filter((p) => p.id !== selectedProduct.id));
      } else {
        setProductsWithBarcode((prev) =>
          prev.map((p) =>
            p.id === selectedProduct.id ? { ...p, barcode: newBarcode } : p
          )
        );
      }

      handleCloseModal();
      if (!selectedProduct.barcode) {
        window.location.reload(); // Refresh only if barcode is being added, not updated
      }
      
    } catch (error) {
      setError('Failed to update barcode.');
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSuccess(false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <SleekHeader>
        <HeaderContent>
          <BackButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </BackButton>

          <HeaderTitle variant="h5">Product Barcodes Management</HeaderTitle>
        </HeaderContent>

        <Typography variant="body1" color="textSecondary">
          Keep your product barcodes organized
        </Typography>
      </SleekHeader>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Tabs
        value={value}
        onChange={handleTabChange}
        centered
        sx={{
          zoom: 1.25, // Preserve your zoom level
          '& .MuiTabs-indicator': { backgroundColor: '#0072ff' },
        }}
      >
        <Tab label="Without Barcodes" />
        <Tab label="With Barcodes" />
      </Tabs>

      {loading ? (
        <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <>
          <TabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Products Without Barcodes ({productsWithoutBarcode.filter(product => product.currentInventory > 0).length})
          </Typography>
          {productsWithoutBarcode.filter(product => product.currentInventory > 0).length === 0 ? (
            <Typography variant="body1" align="center">
              All products have barcodes or have zero inventory.
            </Typography>
          ) : (
            <FancyTable component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Inventory</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsWithoutBarcode
                    .filter(product => product.currentInventory > 0) // Filter out products with zero inventory
                    .map((product, index) => (
                      <TableRow key={product.id} hover>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{product.title}</TableCell>
                        <TableCell>{product.currentInventory}</TableCell>
                        <TableCell>
                          <FancyButton
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenModal(product)}
                          >
                            Add Barcode
                          </FancyButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </FancyTable>
          )}
        </TabPanel>

          <TabPanel value={value} index={1}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Products With Barcodes ({productsWithBarcode.length})
            </Typography>
            <FancyTable component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Barcode</TableCell>
                    <TableCell>Inventory</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsWithBarcode.map((product, index) => (
                    <TableRow key={product.id} hover>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{product.title}</TableCell>
                      <TableCell>{product.barcode}</TableCell>
                      <TableCell>{product.currentInventory}</TableCell>
                      <TableCell>
                        <FancyButton
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenModal(product)}
                        >
                          Update Barcode
                        </FancyButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </FancyTable>
          </TabPanel>
        </>
      )}

      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Slide direction="down" in={openModal} mountOnEnter unmountOnExit>
          <Paper
            sx={{
              p: 4,
              width: '400px',
              margin: 'auto',
              mt: '20vh',
              borderRadius: '16px',
              boxShadow: 3,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              {selectedProduct?.barcode
                ? `Update Barcode for ${selectedProduct?.title}`
                : `Add Barcode for ${selectedProduct?.title}`}
            </Typography>
            <TextField
              label="Barcode"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={newBarcode}
              onChange={(e) => setNewBarcode(e.target.value)}
            />
            <FancyButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSaveBarcode}
            >
              Save
            </FancyButton>
          </Paper>
        </Slide>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
          icon={<CheckCircleIcon />}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default ProductsPage;
