import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Grid,
  Autocomplete,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'; // Add this if you're using DeleteIcon as well
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

// Keyframe animation for spinning the loading indicator
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1rem',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// Define StyledTableRow as a custom styled component
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const POEdit = () => {
  const { id } = useParams();
  const [poData, setPoData] = useState({
    poName: '',
    platform: '',
    products: [],
    masterPoId: null, // To store master PO info
  });  
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [warning, setWarning] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPO = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://shopifybackend.mustafaemresahin.com/api/pos/${id}`);
        setPoData(response.data);
      } catch (error) {
        console.error('Error fetching PO:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchShopifyProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/shopify-products');
        setAllProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPO();
    fetchShopifyProducts();
  }, [id]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleNavigation = (targetPath) => {
    if (unsavedChanges) {
      const userConfirmed = window.confirm("You have unsaved changes. Are you sure you want to leave this page?");
      if (!userConfirmed) return;
    }
    navigate(targetPath);
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setUnsavedChanges(false);
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/pos/${id}`, poData);
      setTimeout(() => setSaving(false), 1000);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 2000);
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error saving PO:', error);
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://shopifybackend.mustafaemresahin.com/api/pos/${id}`);
      setShowDeleteModal(false);
      navigate('/po-list');
    } catch (error) {
      console.error('Error deleting PO:', error);
    }
  };

  const handleAddProduct = () => {
    if (!selectedProduct || quantity <= 0) {
      setWarning('Please select a product and enter a valid quantity.');
      return;
    }

    setWarning('');
    const productPrice = parseFloat(selectedProduct.price) || 0;

    setPoData((prevData) => ({
      ...prevData,
      products: [
        ...prevData.products,
        {
          productId: selectedProduct.id,
          productTitle: selectedProduct.title || 'No Title Available',
          quantityOrdered: quantity,
          unitPrice: productPrice,
        },
      ],
    }));
    setSelectedProduct(null);
    setQuantity('');
    setUnsavedChanges(true);
  };

  const handleRemoveProduct = (index) => {
    setPoData((prevData) => {
      const newProducts = [...prevData.products];
      newProducts.splice(index, 1);
      return { ...prevData, products: newProducts };
    });
    setUnsavedChanges(true);
  };

  const calculateTotalPO = () => {
    return poData.products.reduce((total, product) => total + ((parseFloat(product.unitPrice) || 0) * product.quantityOrdered), 0);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  if (loading) {
    return <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />;
  }

  if (!poData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Button variant="contained" color="info" onClick={() => handleNavigation('/po-list')} sx={{ mb: 2 }}>
        Go Back
      </Button>
      <Typography variant="h4" gutterBottom>Edit Purchase List</Typography>
      
      {/* Master PO Information */}
{poData.masterPoId && (
  <Box
    sx={{
      mb: 3,
      p: 2,
      border: '1px solid',
      borderColor: '#4A90E2', // Custom border color (light blue)
      borderRadius: '8px',
      backgroundColor: '#F5F7FA', // Light background
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        color: '#2C3E50', // Darker blue-gray color for the title
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
      }}
    >
      Purchase Order
    </Typography>
    <Link
      component={RouterLink}
      to={`/master-po/${poData.masterPoId._id}`} // Dynamic link to Master PO page
      underline="hover"
      sx={{
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#D35400', // Orange color for the link
        '&:hover': {
          color: '#E67E22', // Lighter orange on hover
        },
      }}
    >
      {poData.masterPoId.poName}
    </Link>
  </Box>
)}

      {/* Platform dropdown */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Platform</InputLabel>
        <Select
          value={poData.platform}
          onChange={(e) => {
            setPoData({ ...poData, platform: e.target.value });
            setUnsavedChanges(true);
          }}
        >
          <MenuItem value="Shopify">Shopify</MenuItem>
          <MenuItem value="Amazon">Amazon</MenuItem>
          <MenuItem value="Walmart">Walmart</MenuItem>
          {/* Add more platforms as needed */}
        </Select>
      </FormControl>

      <TextField
        label="Purchase List Name"
        fullWidth
        value={poData.poName}
        onChange={(e) => {
          setPoData({ ...poData, poName: e.target.value });
          setUnsavedChanges(true);
        }}
        sx={{ mb: 2 }}
      />

      <Typography variant="h5" gutterBottom>Products</Typography>

      {warning && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {warning}
        </Alert>
      )}

      <Grid container spacing={2} sx={{ mb: 3, alignItems: 'center' }}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={allProducts}
            getOptionLabel={(option) => (option.title ? option.title.replace(' - Default Title', '') : 'Unnamed Product')}
            value={selectedProduct}
            onChange={(event, newValue) => setSelectedProduct(newValue)}
            renderInput={(params) => <TextField {...params} label="Search Product" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProduct}
            sx={{ width: '100%', borderRadius: '8px', height: '50px' }}
          >
            Add Product
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ mt: 2, boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
              Product Name
            </StyledTableCell>
            <StyledTableCell
              sx={{ fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e3f2fd', width: '120px' }}
            >
              Quantity
            </StyledTableCell>
            <StyledTableCell
              sx={{ fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e3f2fd', width: '120px' }}
            >
              Unit Price
            </StyledTableCell>
            <StyledTableCell sx={{ fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
              Total
            </StyledTableCell>
            <StyledTableCell sx={{ fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
              Actions
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {poData.products.map((product, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {product.productTitle ? product.productTitle.replace(' - Default Title', '') : 'Unnamed Product'}
                </Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ width: '150px' }}>
                <TextField
                  type="number"
                  value={product.quantityOrdered}
                  onChange={(e) => {
                    const updatedProducts = [...poData.products];
                    updatedProducts[index].quantityOrdered = parseInt(e.target.value, 10);
                    setPoData({ ...poData, products: updatedProducts });
                    setUnsavedChanges(true);
                  }}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <EditIcon color="primary" sx={{ mr: 1 }} />,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': { borderRadius: 1 },
                    '& .MuiInputBase-input': { textAlign: 'center' }, // Center-align the input text
                    padding: '0.5rem',
                  }}
                />
              </StyledTableCell>
              <StyledTableCell sx={{ width: '200px' }}>
                <TextField
                  type="number"
                  value={product.unitPrice}
                  onChange={(e) => {
                    const updatedProducts = [...poData.products];
                    updatedProducts[index].unitPrice = parseFloat(e.target.value) || 0;
                    setPoData({ ...poData, products: updatedProducts });
                    setUnsavedChanges(true);
                  }}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <EditIcon color="primary" sx={{ mr: 1 }} />,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': { borderRadius: 1 },
                    '& .MuiInputBase-input': { textAlign: 'center' }, // Center-align the input text
                    padding: '0.5rem',
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body2" sx={{ color: 'success.main', fontWeight: 'bold', fontSize: '16px' }}>
                  ${(parseFloat(product.unitPrice) * product.quantityOrdered).toFixed(2)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip title="Remove Product" arrow>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveProduct(index)}
                    sx={{ borderRadius: '8px' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {poData.products.length > 0 && (
            <StyledTableRow>
              <StyledTableCell colSpan={3} sx={{ fontSize: '1.1rem', fontWeight: 'bold', textAlign: 'right' }}>
                Purchase List Total
              </StyledTableCell>
              <StyledTableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                ${calculateTotalPO().toFixed(2)}
              </StyledTableCell>
              <StyledTableCell />
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>

      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
        <Button
          variant="contained"
          color={success ? 'success' : 'success'}
          onClick={handleSave}
          sx={{
            maxHeight: '35px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '150px',
            transition: 'background-color 0.3s ease',
          }}
          disabled={saving || success}
        >
          {saving ? (
            <CircularProgress size={24} sx={{ color: 'white', animation: `${spin} 2s linear infinite` }} />
          ) : success ? (
            <CheckCircleIcon sx={{ color: 'white' }} />
          ) : (
            'Save Changes'
          )}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setShowDeleteModal(true)}
          sx={{ borderRadius: '8px', maxHeight: '35px' }}
        >
          Delete Purchase List
        </Button>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
        Changes saved successfully!
        </Alert>
      </Snackbar>

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this purchase list? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default POEdit;
