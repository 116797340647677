import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [todaysSales, setTodaysSales] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  // Fetch today's sales data when the component mounts
  useEffect(() => {
    axios
      .get('https://shopifybackend.mustafaemresahin.com/api/todays-sales')
      .then((response) => {
        setTodaysSales(response.data);
        //console.log(response.data);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error("Error fetching today's sales:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  // Navigate to the today's graphs page
  const handleViewGraphs = () => {
    navigate('/todays-graphs');
  };

  // Navigate to the product page when a title is clicked
  const titleClick = (productId) => {
    navigate(`/todays-graphs/product/${productId}`);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2e3b55' }}>
        Dashboard
      </Typography>
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2e3b55' }}>
          Today's Sales
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {loading ? ( // Show loading spinner if loading
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}>
                    Product Title
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}>
                    Quantity Sold
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todaysSales.map((product) => (
                  <TableRow hover key={product.shopifyId}>
                    <TableCell
                      onClick={() => titleClick(product.shopifyId)}
                      sx={{ cursor: 'pointer', color: '#1976d2', fontWeight: 'bold' }}
                    >
                      {product.title}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', color: '#333' }}>
                      {product.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!loading && ( // Show button only when loading is false
          <Button
            variant="contained"
            sx={{
              mt: 3,
              backgroundColor: '#1976d2',
              color: '#fff',
              ':hover': {
                backgroundColor: '#115293',
              },
            }}
            onClick={handleViewGraphs}
          >
            View Graphs of Today's Sales
          </Button>
        )}
      </Paper>
    </Box>
  );
}

export default Dashboard;
