import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import axios from 'axios';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function InventoryGraph({ productId }) {
  const [inventoryData, setInventoryData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (productId) {
      setLoading(true);
      
      // Fetch inventory and sales data
      const fetchInventoryData = axios.get(`https://shopifybackend.mustafaemresahin.com/api/inventory/${productId}`);
      const fetchSalesData = axios.get(`https://shopifybackend.mustafaemresahin.com/api/sales-since-august-11`, { params: { productId } });

      Promise.all([fetchInventoryData, fetchSalesData])
        .then(([inventoryResponse, salesResponse]) => {
          setInventoryData(inventoryResponse.data);

          // Create a map for sales data by date
          const salesMap = new Map();
          salesResponse.data.forEach(order => {
            order.line_items.forEach(item => {
              if (item.product_id.toString() === productId) {
                const date = new Date(order.processed_at).toISOString().split('T')[0];
                salesMap.set(date, (salesMap.get(date) || 0) + item.quantity);
              }
            });
          });

          // Generate all dates between start and end date
          const startDate = new Date('2024-08-11');
          const endDate = new Date();
          const dateArray = [];
          let currentDate = startDate;

          while (currentDate <= endDate) {
            const dateStr = currentDate.toISOString().split('T')[0];
            dateArray.push(dateStr);
            currentDate.setDate(currentDate.getDate() + 1);
          }

          // Create final sales data array with zero-filled dates
          const finalSalesData = dateArray.map(date => ({
            date: date,
            quantity: salesMap.get(date) || 0
          }));

          setSalesData(finalSalesData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
  }, [productId]);

  // Merge inventory and sales data for comparison
  const mergedData = inventoryData.map(inventoryEntry => {
    const salesEntry = salesData.find(sale => sale.date === inventoryEntry.date.split('T')[0]);
    return {
      date: inventoryEntry.date.split('T')[0],
      inventory: inventoryEntry.quantity,
      sales: salesEntry ? salesEntry.quantity : 0,
    };
  });

  // Identify mismatches based on the new logic
  const mismatches = mergedData.map((entry, index) => {
    const previousInventory = index > 0 ? mergedData[index - 1].inventory : entry.inventory;
    const nextInventory = index < mergedData.length - 1 ? mergedData[index + 1].inventory : entry.inventory;
    const inventoryChangePrevious = entry.inventory - previousInventory;
    const inventoryChangeNext = nextInventory - entry.inventory;
    let isMismatch = false;

    // Check if today's inventory change matches today's sales, or if tomorrow's change matches today's sales
    if (inventoryChangePrevious !== -entry.sales && inventoryChangeNext !== -entry.sales) {
      isMismatch = true;
    }
    //console.log(previousInventory, nextInventory, inventoryChangePrevious, inventoryChangeNext, entry.date);

    return { ...entry, mismatch: isMismatch };
  });

  // Prepare data for chart
  const labels = mismatches.map(entry => entry.date);
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Inventory Levels',
        data: mismatches.map(entry => entry.inventory),
        fill: false,
        borderColor: (context) => {
          const index = context.dataIndex;
          return index !== undefined && mismatches[index] && mismatches[index].mismatch ? 'red' : '#1976d2';
        },
        borderWidth: 2,
        tension: 0.1,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBackgroundColor: mismatches.map(entry => entry.mismatch ? 'red' : '#1976d2'),
        segment: {
          borderColor: (ctx) => {
            const p2Index = ctx.p2DataIndex;
            return p2Index !== undefined && mismatches[p2Index] && mismatches[p2Index].mismatch ? 'red' : '#1976d2';
          },
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (context) => `Inventory: ${context.raw}`,
          title: (context) => mismatches[context[0].dataIndex]?.date || 'Date: N/A',
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true, title: { display: true, text: 'Inventory Level' } },
    },
  };

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="loader" style={{
            border: '16px solid #f3f3f3', // Light gray border
            borderTop: '16px solid #1976d2', // Spinner color (matching chart line color)
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            animation: 'spin 2s linear infinite'
          }}></div>
        </div>
      ) : (
        <>
          <h3>Inventory Levels</h3>
          <Line data={data} options={options} />
        </>
      )}
      {/* Add keyframes for the loading spinner animation */}
      <style jsx="true">{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
}

export default InventoryGraph;
