import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Modal,
  TextField,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const InventoryCountSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [open, setOpen] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/inventory-counts');
        setSessions(response.data);
      } catch (error) {
        console.error('Error fetching sessions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const createNewSession = async () => {
    if (!newSessionName) return;

    try {
      const response = await axios.post('https://shopifybackend.mustafaemresahin.com/api/inventory-counts', { sessionName: newSessionName });
      setSessions(prevSessions => [response.data, ...prevSessions]);
      setOpen(false);
      setNewSessionName('');
      setSnackbarMessage('Session created successfully');
      setOpenSnackbar(true);
      navigate(`/inventory-count/${response.data._id}`);
    } catch (error) {
      console.error('Error creating session:', error);
    }
  };

  const handleSessionClick = (sessionId) => {
    navigate(`/inventory-count/${sessionId}`);
  };

  const handleDeleteClick = (sessionId) => {
    setSessionToDelete(sessionId);
    setDeleteModalOpen(true);
  };

  const deleteSession = async () => {
    try {
      await axios.delete(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${sessionToDelete}`);
      setSessions(prevSessions => prevSessions.filter(session => session._id !== sessionToDelete));
      setDeleteModalOpen(false);
      setSessionToDelete(null);
      setSnackbarMessage('Session deleted successfully');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Inventory Count Sessions
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
  <Button
    variant="contained"
    color="primary"
    onClick={() => setOpen(true)}
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      padding: '10px 20px',
      transition: '0.3s',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    Create New Session
  </Button>
  <Button
    variant="contained"
    color="primary"
    onClick={() => navigate('/inventory-count/barcodes')}
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      padding: '10px 20px',
      transition: '0.3s',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    Barcodes
  </Button>
</Box>


      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Session Name</TableCell>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Created Date</TableCell>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.length > 0 ? (
                  sessions.map(session => (
                    <TableRow key={session._id}>
                      <TableCell sx={{ fontSize: '0.95rem' }}>{session.sessionName}</TableCell>
                      <TableCell sx={{ fontSize: '0.95rem' }}>{session.createdAt ? new Date(session.createdAt).toLocaleDateString() : '-'}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleSessionClick(session._id)} sx={{ marginRight: 1, fontSize: '0.85rem' }}>
                          View
                        </Button>
                        <Button variant="outlined" color="error" onClick={() => handleDeleteClick(session._id)} sx={{ fontSize: '0.85rem' }}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center" sx={{ fontSize: '1rem' }}>
                      No Inventory Count Sessions available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {/* Modal for creating new session */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2, width: '300px', mx: 'auto', mt: '20vh', outline: 'none' }}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2, fontSize: '1.2rem' }}>
            Enter New Session Name
          </Typography>
          <TextField
            label="Session Name"
            variant="outlined"
            fullWidth
            value={newSessionName}
            onChange={(e) => setNewSessionName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={createNewSession} fullWidth sx={{ fontSize: '1rem' }}>
            Create Session
          </Button>
        </Paper>
      </Modal>

      {/* Modal for delete confirmation */}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2, width: '300px', mx: 'auto', mt: '20vh', outline: 'none' }}>
          <Typography id="delete-modal-title" variant="h6" component="h2" sx={{ mb: 2, fontSize: '1.2rem' }}>
            Confirm Deletion
          </Typography>
          <Typography id="delete-modal-description" sx={{ mb: 3, fontSize: '1rem' }}>
            Are you sure you want to delete this session?
          </Typography>
          <Button variant="contained" color="error" onClick={deleteSession} fullWidth sx={{ mb: 2, fontSize: '1rem' }}>
            Delete
          </Button>
          <Button variant="outlined" onClick={() => setDeleteModalOpen(false)} fullWidth sx={{ fontSize: '1rem' }}>
            Cancel
          </Button>
        </Paper>
      </Modal>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default InventoryCountSessions;
