import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function OrdersGraph({ inventoryLevels }) {
  const [loading, setLoading] = useState(true); // Loading state
  const [data, setData] = useState(null); // Data state for chart

  useEffect(() => {
    setLoading(true); // Set loading to true when starting data preparation

    // Simulate data preparation/loading
    setTimeout(() => {
      const labels = inventoryLevels.map(entry => entry.date.split('T')[0]);
      const ordersData = inventoryLevels.map((entry, index) => {
        if (index === 0) return { date: labels[index], value: 0 };
        return {
          date: labels[index],
          value: Math.max(0, entry.quantity - inventoryLevels[index - 1].quantity),
        };
      });

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Orders',
            data: ordersData.map(entry => entry.value),
            fill: false,
            borderColor: '#4caf50', // Green color for the line
            tension: 0.1,
            pointHitRadius: 5,
            pointHoverRadius: 5,
            pointBackgroundColor: '#4caf50', // Green color for the points
          },
        ],
      };

      setData(chartData); // Set prepared data
      setLoading(false); // Set loading to false after data is ready
    }, 500); // Simulate delay (remove or adjust as needed)
  }, [inventoryLevels]);

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (context) => `Orders: ${context.raw}`,
          title: (context) => data.labels[context[0].dataIndex] || 'Date: N/A',
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true, title: { display: true, text: 'Quantity Ordered' } },
    },
  };

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="loader" style={{
            border: '16px solid #f3f3f3', // Light gray border
            borderTop: '16px solid #4caf50', // Green color for the spinner
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            animation: 'spin 2s linear infinite'
          }}></div>
        </div>
      ) : (
        <>
          <h3>Orders</h3>
          <Line data={data} options={options} />
        </>
      )}
      {/* Add keyframes for the loading spinner animation */}
      <style jsx="true">{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
}

export default OrdersGraph;
