import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PODetails = () => {
  const { id } = useParams();
  const [po, setPo] = useState(null);

  useEffect(() => {
    const fetchPO = async () => {
      try {
        const response = await axios.get(`https://shopifybackend.mustafaemresahin.com/api/pos/${id}`);
        setPo(response.data);
      } catch (error) {
        console.error('Error fetching PO:', error);
      }
    };

    fetchPO();
  }, [id]);

  const handleChangeStatus = async (newStatus) => {
    try {
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/pos/${id}`, { status: newStatus });
      setPo((prevPo) => ({ ...prevPo, status: newStatus }));
    } catch (error) {
      console.error('Error updating PO status:', error);
    }
  };

  if (!po) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>PO Details</Typography>
      <Typography variant="body1">PO Name: {po.poName}</Typography>
      <Typography variant="body1">Platform: {po.platform}</Typography>
      <Typography variant="body1">Status: {po.status}</Typography>
      <Typography variant="body1">Order Date: {po.orderDate ? new Date(po.orderDate).toLocaleDateString() : '-'}</Typography>
      <Typography variant="body1">Expected Delivery Date: {po.expectedDeliveryDate ? new Date(po.expectedDeliveryDate).toLocaleDateString() : '-'}</Typography>

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => handleChangeStatus('Ordered')} sx={{ mr: 1 }}>
          Mark as Ordered
        </Button>
        <Button variant="contained" color="secondary" onClick={() => handleChangeStatus('Received')} sx={{ mr: 1 }}>
          Mark as Received
        </Button>
        <Button variant="contained" color="error" onClick={() => handleChangeStatus('Backordered')}>
          Mark as Backordered
        </Button>
      </Box>
    </Container>
  );
};

export default PODetails;
