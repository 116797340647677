import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function SoldGraphs({ productId }) {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productTitle, setProductTitle] = useState(''); // State to store the product title

  useEffect(() => {
    setLoading(true); // Set loading to true when starting data fetch
  
    // Fetch product title using productId
    axios.get(`https://shopifybackend.mustafaemresahin.com/api/product/${productId}`)
      .then(response => {
        setProductTitle(response.data.title || ''); // Set the product title from the response
      })
      .catch(error => {
        console.error('Error fetching product details:', error);
        setProductTitle('Unknown Product'); // Fallback in case of error
      });
  
    // Fetch sales data for the product
    axios.get(`https://shopifybackend.mustafaemresahin.com/api/sales-since-august-11`, {
      params: { productId }
    })
      .then(response => {
        if (response.data && Array.isArray(response.data)) {
          const salesMap = new Map();
  
          // Populate salesMap with sales data from API
          response.data.forEach(order => {
            if (order.line_items) { // Check if line_items exist
              order.line_items.forEach(item => {
                if (item.product_id.toString() === productId) {
                  const date = new Date(order.processed_at).toISOString().split('T')[0];
                  salesMap.set(date, (salesMap.get(date) || 0) + item.quantity);
                }
              });
            }
          });
  
          // Generate all dates between start and end date
          const startDate = new Date('2024-08-11');
          const endDate = new Date();
          const dateArray = [];
          let currentDate = startDate;
  
          while (currentDate <= endDate) {
            const dateStr = currentDate.toISOString().split('T')[0];
            dateArray.push(dateStr);
            currentDate.setDate(currentDate.getDate() + 1);
          }
  
          // Create final sales data array with zero-filled dates
          const finalSalesData = dateArray.map(date => ({
            date: date,
            quantity: salesMap.get(date) || 0
          }));
  
          setSalesData(finalSalesData);
        } else {
          console.error('Unexpected response format:', response.data);
        }
  
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
        setLoading(false); // Ensure loading is false in case of error
      });
  }, [productId]);  

  // Prepare data for the chart
  const data = {
    labels: salesData.map((_, index) => `Data Point ${index + 1}`), // Use generic labels instead of dates
    datasets: [
      {
        label: 'Quantity Sold', // Dataset label for legend
        data: salesData.map(entry => entry.quantity),
        fill: false,
        borderColor: '#1976d2', // Blue color for the line
        tension: 0.2,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBackgroundColor: '#1976d2', // Blue color for the points
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide the legend to match DashGraphs style
      tooltip: {
        enabled: false, // Disable tooltips completely to match DashGraphs style
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      x: { 
        beginAtZero: true,
        ticks: {
          callback: () => '', // Hide x-axis labels
        },
      },
      y: { 
        beginAtZero: true 
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '400px' }} onClick={() => window.location.href = `/product/${productId}`}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="loader" style={{
            border: '16px solid #f3f3f3',
            borderTop: '16px solid #1976d2', // Blue color for the spinner
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            animation: 'spin 2s linear infinite'
          }}></div>
        </div>
      ) : (
        <>
          <h6 style={{ textAlign: 'center', cursor: 'pointer' }}>{productTitle}</h6>
          <Line data={data} options={options} />
        </>
      )}
      {/* Add keyframes for the loading spinner animation */}
      <style jsx="true">{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
}

export default SoldGraphs;
