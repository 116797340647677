import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Tabs, Tab } from '@mui/material';
import DashGraphs from './DashGraphs';
import ProductSelector from './ProductSelector';
import SoldGraphs from './SoldGraphs'; // Import SoldGraphs component
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function GraphsPage() {
  const [topProducts, setTopProducts] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentTab, setCurrentTab] = useState(0); // State to control which tab is selected
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch top-selling products
    axios.get('https://shopifybackend.mustafaemresahin.com/api/top-selling-products')
      .then(response => {
        setTopProducts(response.data);
        setFilteredProducts(response.data); // Initialize with top products
      })
      .catch(error => console.error('Error fetching top products:', error));

    // Fetch products sold at least once since January 1st, 2024
    axios.get('https://shopifybackend.mustafaemresahin.com/api/sold-products-since?date=2024-01-01')
      .then(response => {
        setSoldProducts(response.data);
      })
      .catch(error => console.error('Error fetching sold products:', error));
  }, []);

  const handleProductSelect = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    if (currentTab === 0) {
      // Filter top-selling products
      setFilteredProducts(
        topProducts.filter(product => 
          product.title.toLowerCase().includes(searchTerm)
        )
      );
    } else {
      // Filter sold products
      setFilteredProducts(
        soldProducts.filter(product => 
          product.title.toLowerCase().includes(searchTerm)
        )
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setFilteredProducts(newValue === 0 ? topProducts : soldProducts); // Update filtered products based on tab
  };

  return (
    <Paper sx={{ padding: '20px', minHeight: '100vh', backgroundColor: '#ffffff' }}>
      <Box mb={2} width="100%">
        {/* Tabs for switching between top-selling and sold products since Jan 1st */}
        <Tabs value={currentTab} onChange={handleTabChange} centered sx={{zoom: 1.25}}>
          <Tab label="Top-Selling Products" />
          <Tab label="Sold Products Since Jan 1st, 2024" />
        </Tabs>
      </Box>

      <Box mb={2} width="100%">
        <ProductSelector onSearchChange={handleSearchChange} />
      </Box>

      <Grid container spacing={2}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.shopifyId} sx={{ zoom: 1.25 }}>
            {currentTab === 0 ? (
              <DashGraphs 
                productId={product.shopifyId} 
                productTitle={product.title} 
                onClick={() => handleProductSelect(product.shopifyId)} 
              />
            ) : (

              <SoldGraphs productId={product.shopifyId} /> // Display SoldGraphs for each sold product
            )}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default GraphsPage;
