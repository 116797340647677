import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  CircularProgress,
  Box,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const POList = () => {
  const [pos, setPos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [poToDelete, setPoToDelete] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPos = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/pos');
        
        // Log the data fetched from the API
        console.log('Fetched Purchase Orders:', response.data);

        setPos(response.data);
      } catch (error) {
        console.error('Error fetching POs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPos();
  }, []);

  const handleEdit = (id) => {
    console.log('Editing PO:', id);  // Debug: Log PO being edited
    navigate(`/po-edit/${id}`);
  };

  const handleDeleteClick = (id) => {
    console.log('Delete button clicked for PO:', id);  // Debug: Log PO deletion request
    setPoToDelete(id);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      console.log('Deleting PO:', poToDelete);  // Debug: Log PO being deleted
      await axios.delete(`https://shopifybackend.mustafaemresahin.com/api/pos/${poToDelete}`);
      setPos(prevPos => prevPos.filter(po => po._id !== poToDelete));
      setDeleteModalOpen(false);
      setPoToDelete(null);
      setSnackbarMessage('Purchase order deleted successfully');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error deleting PO:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Purchase Lists
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/po-create-step1')} sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          Add Purchase List
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Platform</TableCell>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Purchase Order</TableCell>
                  <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pos.length > 0 ? (
                  pos.map((po) => (
                    <TableRow key={po._id}>
                      <TableCell sx={{ fontSize: '0.95rem' }}>{po.poName}</TableCell>
                      <TableCell sx={{ fontSize: '0.95rem' }}>{po.platform}</TableCell>
                      <TableCell sx={{ fontSize: '0.95rem' }}>
                        {po.masterPoId ? (
                          <>
                            {po.masterPoId.poName}
                          </>
                        ) : (
                          'No Purchase Order assigned'
                        )}
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" color="warning" onClick={() => handleEdit(po._id)} sx={{ marginRight: 1, fontSize: '0.85rem' }}>
                          Edit
                        </Button>
                        <Button variant="outlined" color="error" onClick={() => handleDeleteClick(po._id)} sx={{ fontSize: '0.85rem' }}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center" sx={{ fontSize: '1rem' }}>
                      No Purchase Lists available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {/* Modal for delete confirmation */}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2, width: '300px', mx: 'auto', mt: '20vh', outline: 'none' }}>
          <Typography id="delete-modal-title" variant="h6" component="h2" sx={{ mb: 2, fontSize: '1.2rem' }}>
            Confirm Deletion
          </Typography>
          <Typography id="delete-modal-description" sx={{ mb: 3, fontSize: '1rem' }}>
            Are you sure you want to delete this purchase order?
          </Typography>
          <Button variant="contained" color="error" onClick={handleDeleteConfirm} fullWidth sx={{ mb: 2, fontSize: '1rem' }}>
            Delete
          </Button>
          <Button variant="outlined" onClick={() => setDeleteModalOpen(false)} fullWidth sx={{ fontSize: '1rem' }}>
            Cancel
          </Button>
        </Paper>
      </Modal>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default POList;
