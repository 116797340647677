import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Box, Checkbox, FormControlLabel, List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateMasterPO = () => {
  const [poName, setPoName] = useState('');  // State for Master PO name
  const [childPos, setChildPos] = useState([]);  // Selected child POs
  const [availablePos, setAvailablePos] = useState([]);  // Available child POs from backend
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch available child POs from the backend
    const fetchAvailablePos = async () => {
      try {
        const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/available-pos'); // Fetch only available POs
        setAvailablePos(response.data);
      } catch (error) {
        console.error('Error fetching available POs:', error);
        setError('Failed to fetch available POs');
      }
    };

    fetchAvailablePos();
  }, []);

  const handleToggleChildPO = (poId) => {
    // Add or remove PO from selected child POs
    if (childPos.includes(poId)) {
      setChildPos(childPos.filter((id) => id !== poId));  // Remove if already selected
    } else {
      setChildPos([...childPos, poId]);  // Add if not selected
    }
  };

  const handleCreateMasterPO = async () => {
    if (!poName || childPos.length === 0) {
      setError('Please enter a PO name and select at least one child PO.');
      return;
    }

    try {
      const response = await axios.post('https://shopifybackend.mustafaemresahin.com/api/master-pos', {
        poName,
        childPos,
      });
      alert('Master PO created successfully');
      navigate(`/master-po/${response.data._id}`);  // Navigate to the newly created Master PO details page
    } catch (error) {
      console.error('Error creating Master PO:', error);
      setError('Failed to create Master PO');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Create Purchase Order
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box sx={{ mt: 2 }}>
        {/* Input field for Master PO name */}
        <TextField
          label="Purchase Order Name"
          value={poName}
          onChange={(e) => setPoName(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* List of available child POs to select */}
        <Typography variant="h6" gutterBottom>
          Select Child Purchase Lists
        </Typography>

        {availablePos.length === 0 ? (
          // Display message when no available POs
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            No available child POs.
          </Typography>
        ) : (
          <List>
            {availablePos.map((po) => (
              <ListItem key={po._id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={childPos.includes(po._id)}
                      onChange={() => handleToggleChildPO(po._id)}
                    />
                  }
                  label={`${po.poName} (${po.platform})`}
                />
              </ListItem>
            ))}
          </List>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateMasterPO}
          sx={{ mt: 2 }}
        >
          Create Master Purchase Order
        </Button>
      </Box>
    </Container>
  );
};

export default CreateMasterPO;
