import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function DashGraphs({ productId, productTitle }) {
  const [inventoryData, setInventoryData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      axios.get(`https://shopifybackend.mustafaemresahin.com/api/inventory/${productId}`)
        .then(response => setInventoryData(response.data))
        .catch(error => console.error('Error fetching inventory data:', error));
    }
  }, [productId]);

  // Prepare data for the chart
  const data = {
    labels: inventoryData.map((_, index) => `Data Point ${index + 1}`), // Use generic labels instead of dates
    datasets: [
      {
        label: 'Inventory Levels',
        data: inventoryData.map(entry => entry.quantity),
        fill: false,
        borderColor: '#1976d2',
        tension: 0.2,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBackgroundColor: '#1976d2',
      },
    ],
  };

  // Options to completely disable tooltips and adjust interactions
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false, // Disable tooltips completely
      },
    },
    interaction: {
      mode: 'nearest', // Ensure Chart.js handles nearest point only
      intersect: true, // Only interact when the pointer is directly over a point
    },
    scales: {
      x: { 
        beginAtZero: true,
        ticks: {
          callback: () => '', // Hide x-axis labels
        },
      },
      y: { 
        beginAtZero: true 
      },
    },
  };

  const handleClick = () => {
    navigate(`/product/${productId}`);
  };

  return (
    <div style={{ width: '100%' }} onClick={handleClick}>
      {productTitle && (
        <h6 style={{ textAlign: 'center', cursor: 'pointer' }}>
          {productTitle}
        </h6>
      )}
      <Line data={data} options={options} />
    </div>
  );
}

export default DashGraphs;
