import React from 'react';
import { TextField } from '@mui/material';

function ProductSelector({ onSearchChange }) {
  return (
    <TextField
      variant="outlined"
      label="Search Products"
      onChange={onSearchChange}
      fullWidth
    />
  );
}

export default ProductSelector;