import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { Card, CardContent, Typography, Box, Badge } from '@mui/material';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function SalesPageGraphs({ productId, productTitle, quantitySoldToday }) { // Add quantitySoldToday as a prop
  const [inventoryData, setInventoryData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      axios.get(`https://shopifybackend.mustafaemresahin.com/api/inventory/${productId}`)
        .then(response => setInventoryData(response.data))
        .catch(error => console.error('Error fetching inventory data:', error));
    }
  }, [productId]);

  const handleClick = () => {
    navigate(`/todays-graphs/product/${productId}`);
  };

  return (
    <Card sx={{ cursor: 'pointer', width: '100%' }} onClick={handleClick}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h5" gutterBottom>
            {productTitle}
          </Typography>
          <Badge 
            badgeContent={quantitySoldToday} 
            color="primary" 
            sx={{ fontSize: '1rem', backgroundColor: '#f44336', color: '#fff', borderRadius: '8px', padding: '4px 8px', minWidth: '50px', textAlign: 'center' }}
          >
            Sold Today
          </Badge>
        </Box>
        <Line data={{
          labels: inventoryData.map((_, index) => `Data Point ${index + 1}`),
          datasets: [
            {
              label: 'Inventory Levels',
              data: inventoryData.map(entry => entry.quantity),
              fill: false,
              borderColor: '#1976d2',
              tension: 0.2,
              pointHitRadius: 5,
              pointHoverRadius: 5,
              pointBackgroundColor: '#1976d2',
            },
          ],
        }} options={{
          responsive: true,
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
          },
          interaction: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            x: { 
              beginAtZero: true,
              ticks: {
                callback: () => '',
              },
            },
            y: { 
              beginAtZero: true 
            },
          },
        }} />
      </CardContent>
    </Card>
  );
}

export default SalesPageGraphs;