import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Button } from '@mui/material';
import SalesPageGraphs from './SalesPageGraphs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function TodaysGraphsPage() {
  const [todaysSales, setTodaysSales] = useState([]);
  const navigate = useNavigate();

  // Fetch today's sales data when the component mounts
  useEffect(() => {
    axios.get('https://shopifybackend.mustafaemresahin.com/api/todays-sales')
      .then(response => setTodaysSales(response.data))
      .catch(error => console.error("Error fetching today's sales:", error));
  }, []);

  const handleClick = () => {
    navigate(`/`);
  };

  return (
    <Paper sx={{ padding: '20px', minHeight: '100vh', backgroundColor: '#ffffff' }}>
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleClick}>
        Back to dashboard
      </Button>
      <Box>
        <Grid container spacing={2} sx={{marginTop:'10px'}}>
          {todaysSales.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.shopifyId}>
              <SalesPageGraphs 
                productId={product.shopifyId} 
                productTitle={product.title} 
                quantitySoldToday={product.quantity} // Pass the quantity sold today
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

export default TodaysGraphsPage;