import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const POCreateStep1 = () => {
  const [poName, setPoName] = useState('');
  const [platform, setPlatform] = useState('Shopify');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleCreatePO = async () => {
    if (!poName) {
      alert('Please fill out all fields before proceeding.');
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const response = await axios.post('https://shopifybackend.mustafaemresahin.com/api/pos', {
        poName,
        platform,
        products: [], // Initialize with an empty product array
      });
      navigate(`/po-edit/${response.data._id}`); // Navigate to the edit page with the new PO ID
    } catch (error) {
      console.error('Error creating PO:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Create Purchase List</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="PO Name"
            value={poName}
            onChange={(e) => setPoName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Platform</InputLabel>
            <Select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            >
              <MenuItem value="Shopify">Shopify</MenuItem>
              <MenuItem value="Amazon">Amazon</MenuItem>
              <MenuItem value="Walmart">Walmart</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreatePO}
        disabled={isSubmitting}
        sx={{
          width: '150px',
          alignSelf: 'center',
          fontSize: '16px',
          padding: '10px',
          marginRight: '10px',
          marginTop: '10px',
          height: '40px',
          borderRadius: '8px',
        }}
      >
        Create
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => navigate(-1)}
        disabled={isSubmitting}
        sx={{
          width: '150px',
          alignSelf: 'center',
          fontSize: '16px',
          padding: '10px',
          marginTop: '10px',
          height: '40px',
          borderRadius: '8px',
        }}
      >
        Cancel
      </Button>
    </Container>
  );
};

export default POCreateStep1;
