import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function SalesGraph({ productId }) {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setLoading(true); // Set loading to true when starting data fetch
    axios.get(`https://shopifybackend.mustafaemresahin.com/api/sales-since-august-11`, {
      params: { productId }
    })
      .then(response => {
        const salesMap = new Map();

        // Populate salesMap with sales data from API
        response.data.forEach(order => {
          order.line_items.forEach(item => {
            if (item.product_id.toString() === productId) {
              const date = new Date(order.processed_at).toISOString().split('T')[0];
              salesMap.set(date, (salesMap.get(date) || 0) + item.quantity);
            }
          });
        });

        // Generate all dates between start and end date
        const startDate = new Date('2024-08-11');
        const endDate = new Date();
        const dateArray = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
          const dateStr = currentDate.toISOString().split('T')[0];
          dateArray.push(dateStr);
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // Create final sales data array with zero-filled dates
        const finalSalesData = dateArray.map(date => ({
          date: date,
          quantity: salesMap.get(date) || 0
        }));

        setSalesData(finalSalesData);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
        setLoading(false); // Ensure loading is false in case of error
      });
  }, [productId]);

  const labels = salesData.map(entry => entry.date);
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Quantity Sold',
        data: salesData.map(entry => entry.quantity),
        fill: false,
        borderColor: '#f4d03f', // Yellow color for the line
        tension: 0.1,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBackgroundColor: '#f4d03f', // Yellow color for the points
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (context) => `Quantity Sold: ${context.raw}`,
          title: (context) => salesData[context[0].dataIndex]?.date || 'Date: N/A',
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true, title: { display: true, text: 'Quantity Sold' } },
    },
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="loader" style={{
            border: '16px solid #f3f3f3',
            borderTop: '16px solid #f4d03f', // Yellow color for the spinner
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            animation: 'spin 2s linear infinite'
          }}></div>
        </div>
      ) : (
        <>
          <h3>Sales</h3>
          <Line data={data} options={options} />
        </>
      )}
      {/* Add keyframes for the loading spinner animation */}
      <style jsx="true">{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
}

export default SalesGraph;
